const DCGeoJSON = {
  type: 'Feature',
  properties: {
    GEO_ID: '0400000US11',
    STATE: '11',
    NAME: 'District of Columbia',
    LSAD: '',
    CENSUSAREA: 61.048
  },
  geometry: {
    type: 'Polygon',
    coordinates: [
      [
        [-77.038598, 38.791513],
        [-77.038898, 38.800813],
        [-77.035798, 38.814913],
        [-77.038098, 38.815613],
        [-77.039098, 38.821413],
        [-77.038098, 38.828612],
        [-77.039199, 38.832212],
        [-77.041199, 38.833712],
        [-77.042599, 38.833812],
        [-77.043499, 38.833212],
        [-77.044899, 38.834712],
        [-77.044999, 38.838512],
        [-77.044199, 38.840212],
        [-77.041699, 38.840212],
        [-77.032798, 38.841712],
        [-77.031698, 38.850512],
        [-77.039299, 38.864312],
        [-77.038899, 38.865812],
        [-77.039099, 38.868112],
        [-77.040599, 38.871212],
        [-77.043299, 38.874012],
        [-77.045399, 38.875212],
        [-77.046599, 38.874912],
        [-77.045599, 38.873012],
        [-77.046299, 38.871312],
        [-77.049099, 38.870712],
        [-77.051299, 38.873212],
        [-77.051099, 38.875212],
        [-77.054099, 38.879112],
        [-77.055199, 38.880012],
        [-77.058254, 38.880069],
        [-77.063499, 38.888611],
        [-77.067299, 38.899211],
        [-77.068199, 38.899811],
        [-77.070099, 38.900711],
        [-77.0822, 38.901911],
        [-77.0902, 38.904211],
        [-77.0937, 38.905911],
        [-77.1012, 38.911111],
        [-77.1034, 38.912911],
        [-77.1063, 38.919111],
        [-77.1134, 38.925211],
        [-77.1166, 38.928911],
        [-77.1179, 38.932411],
        [-77.1199, 38.934311],
        [-77.1045, 38.94641],
        [-77.1007, 38.94891],
        [-77.0915, 38.95651],
        [-77.054299, 38.98511],
        [-77.040999, 38.99511],
        [-77.036299, 38.99171],
        [-77.015598, 38.97591],
        [-77.013798, 38.97441],
        [-77.008298, 38.97011],
        [-77.002498, 38.96541],
        [-76.935096, 38.913311],
        [-76.909395, 38.892812],
        [-76.910795, 38.891712],
        [-76.919295, 38.885112],
        [-76.920195, 38.884412],
        [-76.949696, 38.861312],
        [-76.953696, 38.858512],
        [-76.979497, 38.837812],
        [-76.992697, 38.828213],
        [-76.999997, 38.821913],
        [-77.001397, 38.821513],
        [-77.038598, 38.791513]
      ]
    ]
  }
};
export { DCGeoJSON };
